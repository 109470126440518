document.addEventListener("DOMContentLoaded", function () {
  // Guardian pattern: Exit early if the screen is wider than 768px (not phone-sized)
  if (window.innerWidth > 768) {
    return;
  }

  const topbars = document.querySelectorAll(
    ".wp-block-bowe-theme-topbar.carousel"
  );

  // Guardian pattern: Exit early if no topbars are found
  if (topbars.length === 0) {
    return;
  }

  topbars.forEach((topbar) => {
    topbar.classList.add("playing");
    const items = topbar.children;
    let currentIndex = 0;

    function updateItems() {
      // Assume all items are not visible by default
      Array.from(items).forEach((item) => item.classList.remove("active"));

      // Make the current item visible
      items[currentIndex].classList.add("active");

      // Prepare index for the next item
      currentIndex = (currentIndex + 1) % items.length;
    }

    // Initialize the carousel
    updateItems();
    setInterval(updateItems, 3000); // Change item every 3 seconds
  });
});
